import Vue from 'vue'
import {
	Message
} from 'element-ui';
import VueRouter from 'vue-router'
import store from './store'

import Login from './views/Login.vue'
import Layout from './views/Layout.vue'

import Home from './views/globalset/Home.vue'
import Startup from './views/globalset/Startup.vue'
import Protocol from './views/globalset/Protocol.vue'
import Faq from './views/globalset/Faq.vue'

import Article from './views/market/Article.vue'

import Userlist from './views/users/Userlist.vue'
import Houselist from './views/users/Houselist.vue'
import Villagelist from './views/users/Villagelist.vue'
import Cnarealist from './views/users/Cnarealist.vue'

import Device from './views/products/Device.vue'
import Devicemeta from './views/products/Devicemeta.vue'

import Vasorder from './views/order/Vasorder.vue'

import Msglist from './views/service/Msglist.vue'
import LeaveMsglist from './views/service/LeaveMsglist.vue'

import MoneyStatistics from './views/statistics/MoneyStatistics.vue'
import ChartStatistics from './views/statistics/ChartStatistics.vue'
import OrderStatistics from './views/statistics/OrderStatistics.vue'

import DbdDevops from './views/dashboard/DbdDevops.vue'
import DbdCnarea from './views/dashboard/DbdCnarea.vue'
import DbdPatrolRooms from './views/dashboard/DbdPatrolRooms.vue'
import DbdMonitorBed from './views/dashboard/DbdMonitorBed.vue'

import Adminers from './views/auth/Adminers.vue'
import Groups from './views/auth/Groups.vue'
import Rules from './views/auth/Rules.vue'

const {
	user,
	pagesMap
} = store.state

const mainPages = [{
		path: '/home',
		title: '控制台',
		component: Home,
		name: Home.name,
	}, {
		path: '/startup',
		title: '启动页图片集',
		component: Startup,
		name: Startup.name,
	}, {
		path: '/faq',
		title: '常见问答',
		component: Faq,
		name: Faq.name,
	}, {
		path: '/protocol',
		title: '协议文本',
		component: Protocol,
		name: Protocol.name,
	},
	{
		path: '/article',
		title: '企宣文案',
		component: Article,
		name: Article.name,
	},
	{
		path: '/user',
		title: '会员列表',
		component: Userlist,
		name: Userlist.name,
	}, {
		path: '/house',
		title: '会员家庭',
		component: Houselist,
		name: Houselist.name,
	}, {
		path: '/village',
		title: '服务小区',
		component: Villagelist,
		name: Villagelist.name,
	}, {
		path: '/cnarea',
		title: '行政地区',
		component: Cnarealist,
		name: Cnarealist.name,
	},
	{
		path: '/device',
		title: '设备列表',
		component: Device,
		name: Device.name,
	}, {
		path: '/devicemeta',
		title: '设备元型',
		component: Devicemeta,
		name: Devicemeta.name,
	},
	{
		path: '/vasorder',
		title: '增值服务订单',
		component: Vasorder,
		name: Vasorder.name,
	},
	{
		path: '/msg',
		title: '消息列表',
		component: Msglist,
		name: Msglist.name,
	},
	{
		path: '/leavemsg',
		title: '用户留言',
		component: LeaveMsglist,
		name: LeaveMsglist.name,
	},
	{
		path: '/moneystatistics',
		title: '资金变动',
		component: MoneyStatistics,
		name: MoneyStatistics.name,
	},
	{
		path: '/chartstatistics',
		title: '图表分析',
		component: ChartStatistics,
		name: ChartStatistics.name,
	},
	{
		path: '/orderstatistics',
		title: '订单统计',
		component: OrderStatistics,
		name: OrderStatistics.name,
	},
	{
		path: '/adminer',
		title: '管理员管理',
		component: Adminers,
		name: Adminers.name,
	},
	{
		path: '/group',
		title: '用户组管理',
		component: Groups,
		name: Groups.name,
	},
	{
		path: '/rule',
		title: '权限管理',
		component: Rules,
		name: Rules.name,
	}
]

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'layout',
		component: Layout,
		redirect: '/home',
		beforeEnter: (to, from, next) => {
			if (!user) {
				store.dispatch('setCallbackUrl', to.path)
				next({
					page: '/login'
				})
			}
			next()
		},
		children: [
			...mainPages
		],
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/dbddevops',
		title: '运营大屏',
		component: DbdDevops,
		name: DbdDevops.name,
	},
	{
		path: '/dbdcnarea',
		title: '地区大屏',
		component: DbdCnarea,
		name: DbdCnarea.name,
	},
	{
		path: '/dbdpatrolrooms',
		title: '房间巡查',
		component: DbdPatrolRooms,
		name: DbdPatrolRooms.name,
	},
	{
		path: '/dbdmonitorbed',
		title: '体征监测',
		component: DbdMonitorBed,
		name: DbdMonitorBed.name,
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (['dbddevops','dbdcnarea','dbdpatrolrooms','dbdmonitorbed'].includes(to.name)) {
		next()
	} else {
		let newTime = parseInt(new Date().getTime() / 1000)
		if ((!store.state.user || newTime > store.state.user.jwt.exp) && to.name != 'login') {
			if (store.state.user && newTime > store.state.user.jwt.exp) {
				Message.error('您的登录信息已过期，请重新登录');
			}
			store.commit('userLogout');
			next('/login');
		}
		const page = mainPages.find(p => p.name == to.name) // 合法页面
		if (page && !store.state.pagesMap.find(p => p.name == to.name)) { // 且未打开
			store.dispatch('updateRoute', [
				...store.state.pagesMap.filter(p => p.name != to.name),
				{
					name: page.name,
					title: page.title,
					path: to.path
				}
			])
		}
		next()
	}
})

export default router