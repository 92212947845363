<template>
    <div class="meta">
        <avatar-cropper :dialogVisible.sync="dialog_avatar_visible" @closeAvatarDialog="closeAvatarDialog">
        </avatar-cropper>
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入设备元型编号或名称" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">新建</el-radio-button>
                    <el-radio-button label="5">正常</el-radio-button>
                    <el-radio-button label="7">仅服务</el-radio-button>
                    <el-radio-button label="9">下架</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="colororange">
            设备元型状态正常，指在售且提供服务；仅服务指提供服务，但不再售卖。
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号">
            </el-table-column>
            <!-- <el-table-column lable="">
                <template slot="header">
                    主图
                    <sub class="colorgray">jpg/png 小于3M</sub>
                </template>
                <template slot-scope="scope">
                    <el-upload class="avatar-uploader" :show-file-list="false" action="#"
                        :http-request="(opts)=>fnUploadRequest(scope.row.id,opts)" :on-success="handleAvatarSuccess"
                        :before-upload="(file)=>beforeAvatarUpload(scope.row.id,file)">
                        <img v-if="scope.row.main_photo_url" :src="scope.row.main_photo_url" class="avatar"
                            style="width: 68px;height:68px;object-fit:contain;">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </template>
            </el-table-column> -->
            <el-table-column label="" align="left">
                <template slot="header">
                    头像
                    <sub class="colorgray">jpg/png 小于3M</sub>
                </template>
                <template slot-scope="scope">
                    <div class="avatar-uploader">
                        <img v-if="scope.row.main_photo_url" class="avatar" style="width: 68px;height:68px;object-fit:contain;" :src="scope.row.main_photo_url" alt="" @click="openAvatarDialog(scope.row.id)" />
                        <i v-else class="el-icon-plus avatar-uploader-icon" @click="openAvatarDialog(scope.row.id)"></i>
                    </div>
                    <el-upload :multiple="false" action="#" :show-file-list="false" :before-upload="beforeUploadAvatar" :http-request="(opt)=>handleUploadAvatar(scope.row.id,opt)">
                        <el-button size="small" type="text">直接上传</el-button>
                    </el-upload>
                </template>
            </el-table-column>
            <el-table-column label="基本信息">
                <template slot-scope="scope">
                    <div>编号：{{scope.row.no}}</div>
                    <div>名称：{{scope.row.name}}</div>
                    <div>种类：{{scope.row.kind_txt}}</div>
                </template>
            </el-table-column>
            <el-table-column label="更多信息">
                <template slot-scope="scope">
                    <div>创建：{{scope.row.create_dt | formatDate}}</div>
                    <div>规格：{{scope.row.spec}}</div>
                    <div>价格：¥{{scope.row.price | formatPrice}}</div>
                </template>
            </el-table-column>
            <el-table-column label="品牌与厂家">
                <template slot-scope="scope">
                    <div>品牌：{{scope.row.brand_name}}</div>
                    <div>标签：{{scope.row.brand_tag}}</div>
                    <div>厂家：{{scope.row.manufacturer_name}}</div>
                </template>
            </el-table-column>
            <el-table-column label="保修与服务">
                <template slot-scope="scope">
                    <div>保修期长：{{scope.row.warranty_period/86400}}天</div>
                    <div>增值服务：¥{{scope.row.vas_fee | formatPrice}}/{{scope.row.vas_period/86400}}天</div>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorblue' : (5==scope.row.status ? 'colorgreen' : (7==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colororange' : ''))))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '新建' : (5==scope.row.status ? '正常' : (7==scope.row.status ? '仅服务' : (9==scope.row.status ? '下架' : ''))))}}</span>
                    <div>已生产：{{scope.row.next_sn_ix}}台</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="375" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small" @click="goSet(scope.row)">修改
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small" @click="handleDeviceMetaStatus(scope.row.id, 0)">删除
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="primary" size="small" @click="handleDeviceMetaStatus(scope.row.id, 1)">新建
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 5" type="success" size="small" @click="handleDeviceMetaStatus(scope.row.id, 5)">正常
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 7" type="success" size="small" @click="handleDeviceMetaStatus(scope.row.id, 7)">仅服务
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 9" type="warning" size="small" @click="handleDeviceMetaStatus(scope.row.id, 9)">下架
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 修改弹窗 -->
        <el-dialog title="设备信息" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="meta-form" :model="metaOnSet" label-width="120px" label-position="right" style="padding: 30px 10px;">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="编号" prop="no">
                            <el-input v-model="metaOnSet.no" :disabled="true" placeholder="请输入编号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="metaOnSet.name" placeholder="请输入名称" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="规格" prop="spec">
                    <el-input v-model="metaOnSet.spec" placeholder="请输入规格" />
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input v-model="metaOnSet.price" placeholder="请输入价格" />
                </el-form-item>
                <el-form-item label="品牌" prop="brand_name">
                    <el-input v-model="metaOnSet.brand_name" placeholder="请输入品牌名称" />
                </el-form-item>
                <el-form-item label="标签" prop="brand_name">
                    <el-input v-model="metaOnSet.brand_tag" placeholder="请输入品牌标签" />
                </el-form-item>
                <el-form-item label="厂家" prop="manufacturer_name">
                    <el-input v-model="metaOnSet.manufacturer_name" placeholder="请输入生产厂家" />
                </el-form-item>
                <el-form-item label="保修期长" prop="warranty_dt">
                    <el-input v-model="metaOnSet.warranty_period" placeholder="请输入保修期长" />
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="13">
                        <el-form-item label="增值服务">
                            <el-input v-model="metaOnSet.vas_fee" placeholder="请输入增值服务费用">
                                <template slot="prepend">¥</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="" label-width="20px">
                            <el-input v-model="metaOnSet.vas_period" placeholder="请输入增值服务期长">
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="">
                    <el-button type="primary" @click="onSave">保存设置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>

</template>
<script>
    import {
        mapState
    } from "vuex";
    import avatarCropper from '@/components/avatarCropper'
    const defaultDeviceMeta = {
        no: '',
        name: '',
        spec: '',
        price: 0.0,
        brand_name: '',
        brand_tag: '',
        manufacturer_name: '',
        warranty_period: 0,
        vas_fee: 0.0,
        vas_period: 0
    }
    const now_dt = new Date().getTime()
    export default {
        name: "Devicemeta",
        components: {
            avatarCropper
        },
        computed: {
            ...mapState(["ossParams"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            }
        },
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                status: "",
                total: 0,
                current_page: 1,
                per_page: 50,
                metaOnSet: defaultDeviceMeta,
                showAddModal: false,
                showSetModal: false,

                //oss_params: {}
                device_meta_id: 0,
                dialog_avatar_visible: false
            };
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                this.$post("/admin/Goods/queryDeviceMeta", {
                        cdit: this.cdit,
                        status: this.status,
                        page_ix: this.current_page,
                        page_size: this.per_page,
                    })
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(meta) {
                this.metaOnSet = {
                    ...meta
                };
                this.metaOnSet.warranty_period /= 86400
                this.metaOnSet.vas_period /= 86400
                this.showSetModal = true;
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.metaOnSet = defaultDeviceMeta;
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data = {
                        name: this.metaOnSet.name,
                        spec: this.metaOnSet.spec,
                        price: this.metaOnSet.price,
                        brand_name: this.metaOnSet.brand_name,
                        brand_tag: this.metaOnSet.brand_tag,
                        manufacturer_name: this.metaOnSet.manufacturer_name,
                        warranty_period: this.metaOnSet.warranty_period * 86400,
                        vas_fee: this.metaOnSet.vas_fee,
                        vas_period: this.metaOnSet.vas_period * 86400
                    }
                    if (this.showSetModal) {
                        data.device_meta_id = this.metaOnSet.id;
                    }
                    this.$post(
                        "/admin/Goods/saveDeviceMeta",
                        data
                    ).finally(() => {
                        this.getList();
                        this.onCloseModal();
                    });
                });
            },
            handleDeviceMetaStatus(device_meta_id, status) {
                this.$confirm(
                    "该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/Goods/saveDeviceMeta", {
                        device_meta_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            beforeUploadAvatar(file) {
                const isIMG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isIMG) {
                    this.$message.error('上传主图只能是 JPG/JPEG/PNG 格式!');
                }
                const isLt3M = file.size / 1024 / 1024 < 3;
                if (!isLt3M) {
                    this.$message.error('上传主图大小不能超过 3MB!');
                }
                return isIMG && isLt3M
            },
            async handleUploadAvatar(device_meta_id, options) {
                const OSS = require('ali-oss')
                const client = new OSS(this.ossParams)
                try {
                    const that = this
                    const file = options.file
                    const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
                    const fileName = `${device_meta_id}_0.${ext}`
                    client.put("device_meta/" + fileName, file).then(res => {
                        if (res.res.statusCode === 200) {
                            const url = res.url + '?t=' + (new Date()).getTime()
							console.log('url',url)
                            that.$post('/admin/Goods/saveDeviceMeta', {
                                device_meta_id: device_meta_id,
                                main_photo_url: url
                            }).finally(() => {
                                that.list.forEach((item, ix) => {
                                    if (device_meta_id == item.id) {
                                        item.main_photo_url = url
                                    }
                                })
                            })
                            const exts = ['jpg', 'jpeg', 'png']
                            let rm_exts = []
                            exts.forEach((item, ix) => {
                                if (ext != item) {
                                    rm_exts.push(`device_meta/${device_meta_id}_0.${item}`)
                                }
                            })
                            client.deleteMulti(rm_exts)
                        } else {
                            this.$message.error('上传失败')
                        }
                    })
                } catch (e) {
                    this.$message.error('上传异常')
                }
            },
            // handleAvatarSuccess(res, file) {},

            openAvatarDialog(meta_id) {
                this.device_meta_id = meta_id
                this.dialog_avatar_visible = true
            },
            closeAvatarDialog(data) {
                const OSS = require('ali-oss')
                const client = new OSS(this.ossParams)
                try {
                    const that = this
                    const fileName = `${this.device_meta_id}_0.png`
                    client.put("device_meta/" + fileName, data).then(res => {
                        if (res.res.statusCode === 200) {
                            const url = res.url + '?t=' + (new Date()).getTime()
                            that.$post('/admin/Goods/saveDeviceMeta', {
                                device_meta_id: that.device_meta_id,
                                main_photo_url: url
                            }).finally(() => {
                                that.list.forEach((item, ix) => {
                                    if (that.device_meta_id == item.id) {
                                        item.main_photo_url = url
                                    }
                                })
                            })
                        } else {
                            this.$message.error('上传失败')
                        }
                    })
                } catch (e) {
                    this.$message.error(e)
                }
            },
        },
    };
</script>
<style lang="less">
    .meta {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            min-width: 660px;
        }


        .avatar-uploader {
            width: 68px;
            height: 68px;
            border: 1px dashed #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 68px;
            height: 68px;
            line-height: 68px;
            text-align: center;
        }

        .avatar {
            width: 68px;
            height: 68px;
            display: block;
        }
    }
</style>